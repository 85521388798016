<template>
    <ContentField>
        404 Not Found  
    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'

export default{
    components:{
        ContentField
    }
}
</script>

<style scoped>
</style>