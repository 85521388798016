<template>
    <div class="matchground">
        <div class="row">
            <div class="col-4">
                <div class="user-photo">
                    <img :src="$store.state.user.photo" alt="">
                </div>
                <div class="user-username">
                    {{ $store.state.user.username }}
                </div>
            </div>

            <div class="col-4">
                <div class="user-select-bot">
                    <select v-model="select_bot" class="form-select" aria-label="Default select example">
                        <option value="-1" selected>御 驾 亲 征</option>
                        <option v-for="bot in bots" :key="bot.id" :value="bot.id">
                            {{ bot.title }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-4">
                <div class="user-photo">
                    <img :src="$store.state.pk.opponent_photo" alt="">
                </div>
                <div class="user-username">
                    {{ $store.state.pk.opponent_username }}
                </div>
            </div>
            <div class="col-12" style="text-align: center; padding-top: 15vh;">
                <button @click="click_match_btn" type="button" class="btn btn-warning btn-lg">{{ match_btn_info }}</button>
            </div>
        </div>
    </div>

    <div class="card-container">
        <div class="card" style="margin-top: -750px;">
            <!-- 卡片内容 -->
            <img src="" class="card-img-top" alt="">
            <div class="card-body">
                <h5 class="card-title" style="color: #333; font-size: 3.0rem;">游戏介绍</h5>
                <p class="card-text" id="introText" style="color: #666; font-size: 1rem;"></p>
                <a class="btn btn-primary" data-bs-toggle="collapse" href="#collapseContent" role="button"
                    aria-expanded="false" aria-controls="collapseContent">
                    展开
                </a>
                <div class="collapse" id="collapseContent">
                    <br> 这是一个双人贪吃蛇对战游戏
                    <br> 1.在准备页面时你可以选择你的出战角色(本人或是 bot)
                    <br> 2.点击开始匹配即可开始游戏 使用W A S D进行上下左右移动
                    <br> 3.在有一方先撞到障碍物或是对方的尾巴时失败,有一方几秒内未读取到操作时 游戏结束
                    <br> 4.注意请勿往蛇头的反方向移动会被直接判负
                    <br> 5.胜利的一方在排行榜上将+5的积分 输为-2 初始为1500分
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from 'vue'
import { useStore } from 'vuex';
import $ from 'jquery';

export default {
    setup() {
        const store = useStore();
        let match_btn_info = ref("开始匹配");
        let bots = ref([]);
        let select_bot = ref("-1");

        const click_match_btn = () => {
            if (match_btn_info.value === "开始匹配") {
                match_btn_info.value = "取消";
                store.state.pk.socket.send(JSON.stringify({
                    event: "start-matching",
                    bot_id: select_bot.value,
                }));
            } else {
                match_btn_info.value = "开始匹配";
                store.state.pk.socket.send(JSON.stringify({
                    event: "stop-matching",
                }));
            }
        }

        const refresh_bots = () => {
            $.ajax({
                url: "https://www.yzmttk.top/api/user/bot/getlist/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    bots.value = resp;
                }
            })
        }

        refresh_bots();  // 从云端动态获取bots

        return {
            match_btn_info,
            click_match_btn,
            bots,
            select_bot,
        }
    }
}
</script>

<style scoped>
div.matchground {
    width: 60vw;
    height: 70vh;
    margin: 40px auto;
    background-color: rgba(50, 50, 50, 0.5);
}

div.user-photo {
    text-align: center;
    padding-top: 10vh;
}

div.user-photo>img {
    border-radius: 50%;
    width: 20vh;
}

div.user-username {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: white;
    padding-top: 2vh;
}

div.user-select-bot {
    padding-top: 25vh;
}

div.user-select-bot>select {
    width: 60%;
    margin: 0 auto;
}

.card-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 2px;
    /* 100%视窗高度，可以根据需要调整 */
    padding-left: 20px;
    /* 左边距，根据需要调整 */
}

.card {
    width: 18rem;
}
</style>